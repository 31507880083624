import { ApolloClient } from '@apollo/client'

export default defineNuxtPlugin(({ app }): void => {
  // upon logout reset cache
  // TODO 
  // app.$auth.$storage.watchState('loggedIn', (loggedIn) => {
  //   if (!loggedIn as boolean) {
  //     Object.values<ApolloClient<any>>((app as any).apolloProvider.clients).forEach((client) => {
  //       client.cache.reset()
  //     })
  //   }
  // })
})
