<template>
  <b-form-group :state="state" :description="description" :label="label" :label-cols="label ? labelCols : 0" breakpoint="md" :label-for="safeId()">
    <b-form-checkbox
      :id="safeId()"
      :state="state"
      :class="['custom-checkbox', stateClass]"
      @blur.native="touch"
      :type="type"
      :checked="checked"
      :readonly="readonly"
      :disabled="disabled"
      @input="$emit('input', $event)">
      <template v-if="!$scopedSlots.default">
        {{ $placeholder }}
      </template>
      <slot v-else />
    </b-form-checkbox>
    <div slot="invalid-feedback" v-for="(feedback, validation) in invalidFeedback" :key="validation">{{ feedback }}</div>
  </b-form-group>
</template>

<script>

import options from './options'
import { formStateMixin } from 'bootstrap-vue/esm/mixins/form-state'

export default {
  model: {
    event: 'input',
    prop: 'checked'
  },
  mixins: [options],
  props: {
    type: {},
    checked: {}
  },
  computed: {
    ...formStateMixin.options.computed
  }
}
</script>

<style>
  .custom-control.custom-checkbox .custom-control-label  {
    cursor: pointer;
  }
  .custom-control.custom-checkbox .custom-control-input:disabled ~ .custom-control-label {
    cursor: default;
  }
</style>
