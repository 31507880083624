
import originAxios, { AxiosError, AxiosInstance } from 'axios'
import Api, { AuthTokenResponse } from '@iris/api'

declare module 'axios' { export interface AxiosRequestConfig { _authRetry?: boolean; } }

const AXIOS_INSTANCE = new WeakMap<Pick<Api, 'getAuthToken'>, AxiosInstance>()

export function autoAuthAxios (api: Pick<Api, 'getAuthToken'>): AxiosInstance {
  if (!AXIOS_INSTANCE.has(api)) {
    /** current auth promise - basically holding a string value */
    let currentAuth: Promise<AuthTokenResponse> | undefined

    const axios = originAxios.create()
    // setup axios in weak map for return below
    AXIOS_INSTANCE.set(api, axios)

    // setup headers
    axios.interceptors.request.use(async function (config) {
      if (!currentAuth) {
        currentAuth = api.getAuthToken()
      }
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${(await currentAuth).accessToken}`
      }
      // return original config
      return config
    })

    axios.interceptors.response.use(function (response) {
      return response
    }, function (error: AxiosError) {
      if (error.response && error.response.status === 401 && !error.config._authRetry) {
        currentAuth = undefined
        // retry request just the once only
        return axios({
          ...error.config,
          _authRetry: true
        })
      }
      // pass original error
      return Promise.reject(error)
    })
  }
  return AXIOS_INSTANCE.get(api)!
}
