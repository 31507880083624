<template>
  <b-form-group :state="state" :description="description" :label="label" :label-cols="label ? labelCols : 0" :breakpoint="labelBreakpoint" :label-for="safeId()">
    <b-form-select
      :id="safeId()"
      :state="state"
      @blur.native="touch"
      :options="options"
      :readonly="readonly"
      :disabled="disabled"
      :value="value"
      :size="size"
      @input="$emit('input', $event)">
      <option v-if="$placeholder" slot="first" :value="null">{{ $placeholder }}</option>
      <slot />
    </b-form-select>
    <div slot="invalid-feedback" v-for="(feedback, validation) in invalidFeedback" :key="validation">{{ feedback }}</div>
  </b-form-group>
</template>

<script>

// import bFormSelect from 'bootstrap-vue/src/components/form-select/form-select'
import options from './options'

export default {
  mixins: [options],
  props: {
    options: {
      type: [Array, Object],
      default () {
        return []
      }
    }
  },
  computed: {
    messagesWithExtraRequired () {
      return Object.assign({ required: this.messageForRequired() }, this.messages)
    }
  },
  methods: {
    messageForRequired () {
      return `Please select a ${this.fieldName || this.label}`
    }
  }
}
</script>
