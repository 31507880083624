import type { Auth, TokenableScheme } from '@nuxtjs/auth-next'
export async function getAuthHeaders(auth: Auth): Promise<{
    Authorization?: string
}> {
    if (auth.loggedIn) {
        const { tokenExpired, refreshTokenExpired, isRefreshable } = auth.check(true)
        if (refreshTokenExpired) {
            auth.reset()
          }
          else if (tokenExpired) {
            // Token has expired. Check if refresh token is available.
            if (isRefreshable) {
              // Refresh token is available. Attempt refresh.
              try {
                await auth.refreshTokens()
              } catch (error) {
                // Reset when refresh was not successfull
                auth.reset()
              }
            } else {
              // Refresh token is not available. Force reset.
              auth.reset()
            }
          }
        // have token can append to request
        return {
            Authorization: (auth.getStrategy() as TokenableScheme).token.get() as string
        }
    }
    return {}
}
