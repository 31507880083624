import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b4a5a522 = () => interopDefault(import('../pages/get-teaching-sessions/index.vue' /* webpackChunkName: "pages/get-teaching-sessions/index" */))
const _72e6bb7d = () => interopDefault(import('../pages/iris.vue' /* webpackChunkName: "pages/iris" */))
const _43e5f040 = () => interopDefault(import('../pages/iris/index.vue' /* webpackChunkName: "pages/iris/index" */))
const _2d763a87 = () => interopDefault(import('../pages/iris/presentations.vue' /* webpackChunkName: "pages/iris/presentations" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _49c57cf4 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _eb9aec2e = () => interopDefault(import('../pages/manual-payment.vue' /* webpackChunkName: "pages/manual-payment" */))
const _0bc03dac = () => interopDefault(import('../pages/manual-payment/index.vue' /* webpackChunkName: "pages/manual-payment/index" */))
const _dbd9b674 = () => interopDefault(import('../pages/manual-payment/return.vue' /* webpackChunkName: "pages/manual-payment/return" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/get-teaching-sessions",
    component: _b4a5a522,
    name: "get-teaching-sessions"
  }, {
    path: "/iris",
    component: _72e6bb7d,
    children: [{
      path: "",
      component: _43e5f040,
      name: "iris"
    }, {
      path: "presentations",
      component: _2d763a87,
      name: "iris-presentations"
    }]
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/logout",
    component: _49c57cf4,
    name: "logout"
  }, {
    path: "/manual-payment",
    component: _eb9aec2e,
    children: [{
      path: "",
      component: _0bc03dac,
      name: "manual-payment"
    }, {
      path: "return",
      component: _dbd9b674,
      name: "manual-payment-return"
    }]
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
