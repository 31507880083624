<template>
  <div class="d-flex align-items-center min-height-40 container">
    <div class="row w-100 justify-content-center">
      <div class="col-md-6">
        <router-link to="/" class="nuxt-link-active">
          <h1 class="float-left display-3 mr-4">
            {{ error.statusCode }}
          </h1>
          <template v-if="error.statusCode === '404'">
            <h4 class="pt-3">Oops! You're lost.</h4>
            <p class="text-muted">
              The page you are looking for was not found.
            </p>
          </template>
          <div v-else>
            <h4 class="pt-3">Oops! Something went wrong!</h4>
            <p class="text-muted">{{ error.message }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  head: {
    title: "Estia Tuition - Something went wrong!",
  },
};
</script>

<style scoped>
.min-height-40 {
  min-height: 40vh;
}
.nuxt-link-active {
  text-decoration: none;
  color: unset;
}
</style>
