declare module '@nuxt/bridge-schema' {
  interface NuxtAppCompat {
    $REGISTRATION_URL: string
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $REGISTRATION_URL: string
  }
}

export default defineNuxtPlugin((ctx) => {
  let registrationBaseUrl: string = ctx.$config.registrationUrl || ctx.$config.public.registrationUrl || '/registration'
  if (registrationBaseUrl.startsWith('/') && typeof window !== 'undefined') {
    registrationBaseUrl = `${window.location.protocol}//${window.location.host}${registrationBaseUrl}`
  }
  return {
    provide: {
      REGISTRATION_URL: registrationBaseUrl
    }
  }
})
