import { Auth, RefreshScheme, RefreshSchemeOptions } from '@nuxtjs/auth-next/dist/runtime'

export default class DynamicConfigRefreshScheme extends RefreshScheme {
  constructor($auth: Auth, options: RefreshSchemeOptions) {
    // upon logout reset cache
    const registrationBaseUrl = $auth.ctx.app.$REGISTRATION_URL
    options.endpoints = {
      logout: false,
      user: {
        url: `${registrationBaseUrl}/api/auth/user`,
        method: 'get'
      },
      refresh: {
        url: `${registrationBaseUrl}/api/auth/refresh`,
        method: 'post'
      },
      login: {
        url: `${registrationBaseUrl}/api/auth/login`,
        method: 'post'
      }
    }
    super($auth, options)
  }
}
