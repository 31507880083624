// import Vue from 'vue'

import RegFormSelect from '@iris/components/forms/RegFormSelect.vue'
import RegFormCheckbox from '@iris/components/forms/RegFormCheckbox.vue'
import RegFormRadio from '@iris/components/forms/RegFormRadio.vue'
import RegFormTextarea from '@iris/components/forms/RegFormTextarea.vue'
import RegFormErrors from '@iris/components/forms/RegFormErrors'

export default defineNuxtPlugin((app) => {
  app.vueApp.component('RegFormSelect', RegFormSelect)
  app.vueApp.component('RegFormCheckbox', RegFormCheckbox)
  app.vueApp.component('RegFormRadio', RegFormRadio)
  app.vueApp.component('RegFormTextarea', RegFormTextarea)
  app.vueApp.component('RegFormErrors', RegFormErrors)
})
