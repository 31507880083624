import { idMixin as safeId } from 'bootstrap-vue/esm/mixins/id'
import debounce from 'lodash/debounce.js'
import flow from 'lodash/fp/flow.js'
import toPairs from 'lodash/fp/toPairs.js'
import filter from 'lodash/fp/filter.js'
import fromPairs from 'lodash/fp/fromPairs.js'
import Vue, { ComponentOptions } from 'vue'

interface FormOptions extends Vue {
  fieldName: string;
  label: string;
  messageForRequired(): string;
  $messages: Record<string, string>;
  messages: Record<string, string>;
  placeholder: string | null | boolean;
  required: boolean;
  messageForMaxLength(len: number) : string;
  messageForMinLength(len: number) : string;
  $computedMessages: Record<string, string>;
}

export default {
  mixins: [safeId],
  props: {
    fieldName: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: [String, Boolean],
      required: false,
      default: null
    },
    description: {
      type: String,
      required: false,
      default: null
    },
    labelCols: {
      type: String,
      default: '4'
    },
    labelBreakpoint: {
      type: String,
      default: 'md'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String
    },
    value: {},
    $v: {
      type: Object,
      default () {
        return {
          $params: {},
          $touch: function () {}
        }
      }
    },
    messages: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      $dirty: false
    }
  },
  computed: {
    invalidFeedback (this: FormOptions): Record<string, string> {
      return flow(
        toPairs,
        filter(([key]) => key in this.$v && !this.$v[key]),
        fromPairs
      )(this.$messages)
    },
    $placeholder (this: FormOptions): string {
      if (this.placeholder === false) {
        return ''
      }
      if (typeof this.placeholder === 'string' && this.placeholder) {
        return this.placeholder
      }
      return `${this.fieldName || this.label}${this.required ? '*' : ''}`
    },
    $computedMessages (this: FormOptions): Record<string, string> {
      let d: Record<string, string> = {}
      for (let validationName in this.$v.$params) {
        let message = `Validation ${validationName} failed for ${this.fieldName || this.label}`
        if (validationName === 'required') {
          message = this.messageForRequired()
        } else if (validationName === 'maxLength') {
          message = this.messageForMaxLength(this.$v.$params[validationName].max)
        } else if (validationName === 'minLength') {
          message = this.messageForMinLength(this.$v.$params[validationName].min)
        } else if (validationName === 'hasSomeUpperCase' || validationName === 'hasSomeLowerCase') {
          message = `${this.fieldName || this.label} needs to be Title case`
        }
        d[validationName] = message
      }
      return d
    },
    $messages (this: FormOptions): Record<string, string> {
      return {
        ...this.$computedMessages,
        ...this.messages
      }
    },
    required (this: FormOptions): boolean {
      return this.$messages.hasOwnProperty('required')
    },
    state (this: FormOptions): boolean | null {
      return this.$v.$dirty ? !this.$v.$error : null
    }
  },
  methods: {
    messageForMaxLength (length) {
      return `${this.label || this.fieldName} needs to be shorter than ${length} characters`
    },
    messageForMinLength (length) {
      return `${this.label || this.fieldName} needs to be longer than ${length} characters`
    },
    messageForRequired () {
      return `${this.label || this.fieldName} is required`
    },
    updateValue: debounce(function (value) {
      this.$emit('input', value)
    }, 250),
    touch () {
      this.$v && this.$v.$touch && this.$v.$touch()
    }
  }
} as ComponentOptions<FormOptions>
