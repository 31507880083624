export default {
  functional: true,
  render (createElement, { props = {} }) {
    let $v = props.$v || {}
    let elementName = props.elementName || 'b-form-invalid-feedback'
    let name = props.name || '{Set Name}'
    let messages = props.messages || {}
    for (let validationName in $v.$params) {
      if (!messages[validationName]) {
        let message = `Validation ${validationName} failed for ${name}`
        if (validationName === 'required') {
          message = `${name} is required`
        } else if (validationName === 'maxLength') {
          message = `${name} needs to be shorter than ${$v.$params[validationName] ? $v.$params[validationName].max : 0} characters`
        } else if (validationName === 'minLength') {
          message = `${name} needs to be longer than ${$v.$params[validationName] ? $v.$params[validationName].min : 0} characters`
        } else if (validationName === 'hasSomeUpperCase' || validationName === 'hasSomeLowerCase') {
          message = `${name} needs to be Title case`
        }
        messages[validationName] = message
      }
    }
    let returnArray = []
    for (let key in messages) {
      if (messages.hasOwnProperty(key) && !$v[key]) {
        returnArray.push(createElement(elementName, messages[key]))
      }
    }
    return returnArray
  }
}
