import { TokenStatus, TokenStatusEnum } from '@nuxtjs/auth-next/dist/runtime'

export default defineNuxtPlugin(() => {
    // from https://github.com/nuxt-community/auth-module/blob/dev/src/inc/token-status.ts#L26
    TokenStatus.prototype._calculate = function(token: string | boolean, tokenExpiresAt: number | false): TokenStatusEnum {
        const now = Date.now()
    
        try {
          if (!token || !tokenExpiresAt) {
            return TokenStatusEnum.UNKNOWN
          }
        } catch (error) {
          return TokenStatusEnum.UNKNOWN
        }
    
        // Give us some slack to help the token from expiring between validation and usage
        const timeSlackMillis = 5000 // changed to 5 seconds
        tokenExpiresAt -= timeSlackMillis
    
        // Token is still valid
        if (now < tokenExpiresAt) {
          return TokenStatusEnum.VALID
        }
    
        return TokenStatusEnum.EXPIRED
    }

})    
