<template>
  <b-form-group :state="state" :description="description" :label="label" :label-cols="label ? labelCols : 0" breakpoint="md" :label-for="safeId()">
    <b-input-group :prepend="prepend" :append="append" v-if="append || prepend || $slots.append || $slots.prepend">
      <b-input-group-prepend v-if="$slots.prepend">
        <slot name="prepend" />
      </b-input-group-prepend>
      <b-form-input
      ref="input"
      :id="safeId()"
      :placeholder="$placeholder"
      :state="state"
      @blur.native="touch"
      :type="type"
      :max="max"
      :min="min"
      :value="value"
      :step="step"
      :size="size"
      :readonly="readonly"
      :disabled="disabled"
      :plaintext="plaintext"
      :formatter="formatter"
      :lazy-formatter="lazyFormatter"
      :autocomplete="autocomplete"
      @input="updateValue"
      @focus.native="$emit('focus', $event)"
      @change="$emit('change', $event)" />
      <b-input-group-append v-if="$slots.append">
        <slot name="append" />
      </b-input-group-append>
    </b-input-group>
    <b-form-input
    v-else
    ref="input"
    :id="safeId()"
    :placeholder="$placeholder"
    :state="state"
    @blur.native="touch"
    :type="type"
    :max="max"
    :min="min"
    :value="value"
    :step="step"
    :size="size"
    :readonly="readonly"
    :disabled="disabled"
    :plaintext="plaintext"
    :formatter="formatter"
    :lazy-formatter="lazyFormatter"
    :autocomplete="autocomplete"
    @input="updateValue"
    @focus.native="$emit('focus', $event)"
    @change="$emit('change', $event)" />
    <div slot="invalid-feedback" v-for="(feedback, validation) in invalidFeedback" :key="validation">{{ feedback }}</div>
  </b-form-group>
</template>

<script>

import { detect } from 'detect-browser'
// import bFormSelect from 'bootstrap-vue/src/components/form-select/form-select'
import options from './options'

const browser = detect()

var autoCompleteStringValue = 'off'
if (browser && browser.name && (browser.name === 'chrome' || browser.name === 'opera')) {
  autoCompleteStringValue = 'nope'
}

export default {
  mixins: [options],
  // model: {
  //   event: 'input',
  //   model: 'value'
  // },
  props: {
    type: {
      type: String,
      required: false
    },
    plaintext: {
      type: Boolean,
      required: false,
      default: false
    },
    max: {
      type: [Number, String],
      required: false
    },
    min: {
      type: [Number, String],
      required: false
    },
    formatter: {
      type: Function,
      required: false
    },
    prepend: {
      type: String,
      required: false
    },
    append: {
      type: String,
      required: false
    },
    step: {
      type: [Number, String],
      required: false
    },
    lazyFormatter: {
      type: Boolean,
      required: false
    },
    autocomplete: {
      type: String,
      default: autoCompleteStringValue
    }
  },
  methods: {
    focus () {
      this.$refs.input.focus()
    },
    nativeValue () {
      return this.$refs.input.value
    }
  }
}
</script>
