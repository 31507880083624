<template>
  <div>
    <TheHeader />
    <b-container v-if="$route.params.success">
      <b-alert show variant="success">{{ $route.params.success }}</b-alert>
    </b-container>
    <Nuxt />
    <TheFooter />
  </div>
</template>

<script lang="ts">
import { useApolloClientInject } from '../utils/apolloClientInjection'

export default defineComponent({
  setup () {
    useApolloClientInject()
    return {}
  },
  head () {
    return {
      meta: [{
        name: 'robots',
        content: this.$config.public.noindex ? ['noindex, nofollow'] : ['index, follow']
      }]
    }
  }
})

</script>
