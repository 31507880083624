import { Context } from '@nuxt/types'

export default ({ $auth, redirect }: Context) =>  {
    if ($auth.user.paymentType === 'ELNM') {
        redirect('/get-teaching-sessions')
    }
}



