<template>
  <b-form-group :state="state" :description="description" :label="label" :label-cols="label ? labelCols : 0" breakpoint="md" :label-for="safeId()">
    <b-input-group :prepend="prepend" :append="append">
      <b-form-textarea
        :id="safeId()"
        :placeholder="$placeholder"
        :state="state"
        @blur.native="touch"
        :value="value"
        :rows="rows"
        :plaintext="plaintext"
        @input="$emit('input', $event)"
        @change="$emit('change', $event)" />
    </b-input-group>
    <div slot="invalid-feedback" v-for="(feedback, validation) in invalidFeedback" :key="validation">{{ feedback }}</div>
  </b-form-group>
</template>

<script>

// import bFormSelect from 'bootstrap-vue/src/components/form-select/form-select'
import options from './options'

export default {
  mixins: [options],
  props: {
    plaintext: {
      type: Boolean,
      required: false,
      default: false
    },
    prepend: {},
    append: {},
    lazyFormatter: {},
    rows: {}
  }
}
</script>
