import $1b0876f1 from "/app/middleware/consultant.ts";
import $3d814ff6 from "/app/middleware/irisRedirect.ts";
import $019450e6 from "/app/middleware/nonMemberRedirect.ts";
import $f98b77c0 from "/app/middleware/parent.ts";
import $350deead from "/app/middleware/portaluser.ts";
const middleware = {
  consultant: $1b0876f1,
  irisRedirect: $3d814ff6,
  nonMemberRedirect: $019450e6,
  parent: $f98b77c0,
  portaluser: $350deead
}
export default middleware