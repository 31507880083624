<template>
  <b-form-group :state="state" :description="description" :label="label" :label-cols="label ? labelCols : 0" breakpoint="md" :label-for="safeId()">
    <b-form-radio-group
      :id="safeId()"
      :class="['custom-radio', stateClass]"
      :state="state"
      @blur.native="touch"
      :options="options"
      :checked="checked"
      :buttons="buttons"
      :stacked="stacked"
      :disabled="disabled"
      :size="size"
      @input="$emit('input', $event)">
      <slot />
    </b-form-radio-group>
    <div slot="invalid-feedback" v-for="(feedback, validation) in invalidFeedback" :key="validation">{{ feedback }}</div>
  </b-form-group>
</template>

<script>

// import bFormSelect from 'bootstrap-vue/src/components/form-select/form-select'
import options from './options'
import { formStateMixin } from 'bootstrap-vue/esm/mixins/form-state'

export default {
  mixins: [options],
  model: {
    event: 'input',
    prop: 'checked'
  },
  computed: {
    ...formStateMixin.options.computed
  },
  props: {
    options: {
      type: [Array, Object],
      default () {
        return []
      }
    },
    buttons: {
      type: Boolean,
      default: false
    },
    checked: {},
    stacked: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    messageForRequired () {
      return `Please select a ${this.fieldName}`
    }
  }
}
</script>
<style>
  .custom-control.custom-radio .custom-control-label  {
    cursor: pointer;
  }
  .custom-control.custom-radio .custom-control-input:disabled ~ .custom-control-label {
    cursor: default;
  }
</style>
